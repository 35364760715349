import {
  CatchError,
  cleanFilters,
  formatAxiosErrorToPayload,
  getErrorString,
  getObjectDifferences,
  keysToSnakeCase,
} from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { RootState } from '../app/store'
import { initialFilters, PRIVATE_NETWORK } from '../common/constants'
import {
  NewOrder,
  OrderDetails,
  OrderDocument,
  OrderItem,
  SearchFilters,
  TableOrder,
} from '../common/types'
import { keysToCamelCase, normalizeLoadPayload } from '../common/utils'
import { getLoadDetail } from './loadsSlice'

type OrdersState = {
  loading: {
    getOrders: boolean
    createOrder: boolean
    getOrderDetails: boolean
    archiveOrder: boolean
    getOrderDocuments: boolean
    uploadOrderDocuments: boolean
    deleteOrderDocument: boolean
    updateOrder: boolean
    getOrderConfirmation: boolean
    createLoadFromSelectedOrders: boolean
    linkOrUnlinkOrder: boolean
    getOrdersByQuery: boolean
    bulkArchiveOrders: boolean
  }
  orders: OrderItem[]
  count: number
  offset: number
  limit: number
  filters: SearchFilters
  order: TableOrder
  newOrder: NewOrder
  initialOrdersCount: number
  orderDetails: OrderDetails
  documents: OrderDocument[]
  ordersToConfirm: OrderDetails[]
  ordersByQuery: OrderItem[]
  ordersByQueryCount: number
  ordersByQueryLimit: number
  ordersByQueryOffset: number
}

const initialState: OrdersState = {
  loading: {
    getOrders: false,
    createOrder: false,
    getOrderDetails: false,
    archiveOrder: false,
    getOrderDocuments: false,
    uploadOrderDocuments: false,
    deleteOrderDocument: false,
    updateOrder: false,
    getOrderConfirmation: false,
    createLoadFromSelectedOrders: false,
    linkOrUnlinkOrder: false,
    getOrdersByQuery: false,
    bulkArchiveOrders: false,
  },
  orders: [],
  count: 0,
  offset: 0,
  limit: 50,
  filters: initialFilters,
  order: { label: '', direction: '', key: '' },
  newOrder: {
    orderPickupNumber: '',
    poNumber: '',
    salesNumber: '',
    stockNumber: '',
    origin: null,
    destination: null,
    budget: '',
    files: [],
  },
  initialOrdersCount: 0,
  orderDetails: {},
  documents: [],
  ordersToConfirm: [],
  ordersByQuery: [],
  ordersByQueryCount: 0,
  ordersByQueryLimit: 50,
  ordersByQueryOffset: 0,
}

export const getOrders = createAsyncThunk(
  'orders/getOrders',
  async (_, { getState, rejectWithValue }) => {
    const { filters, limit, offset } = (getState() as RootState).orders

    try {
      const response = await api.get('shipper/api/list-create-order/', {
        params: {
          ...cleanFilters({
            limit,
            offset,
            archived: filters.archived,
            pickup_city: filters.originCity,
            pickup_state: filters.originState,
            drop_city: filters.destinationCity,
            drop_state: filters.destinationState,
            po_number__icontains: filters.poNumber,
            sales_number__icontains: filters.salesNumber,
            order_pickup_number__icontains: filters.pickupNumber,
            stock_number__icontains: filters.stockNumber,
          }),
        },
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getOrderDetails = createAsyncThunk(
  'orders/getOrderDetails',
  async (id: string | number, { rejectWithValue }) => {
    try {
      const response = await api.get(`shipper/api/order-rud/${id}/`)
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getOrderDocuments = createAsyncThunk(
  'orders/getOrderDocuments',
  async (id: string | number, { rejectWithValue }) => {
    try {
      const response = await api.get(`shipper/api/list-create-order-document/${id}/`, {
        params: { limit: 100 },
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const uploadOrderDocuments = createAsyncThunk(
  'orders/uploadOrderDocuments',
  async (
    { files, id }: { files: { file: File; id: string }[]; id?: string | number },
    { getState, dispatch, rejectWithValue },
  ) => {
    const { orderDetails } = (getState() as RootState).orders

    const formData = new FormData()
    files?.forEach(doc => formData.append('files', doc.file))

    try {
      const response = await api.post(
        `shipper/api/list-create-order-document/${id || orderDetails?.id}/`,
        formData,
      )
      await dispatch(getOrderDocuments(id || orderDetails?.id || ''))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const deleteOrderDocument = createAsyncThunk(
  'orders/deleteOrderDocument',
  async (fileId: number, { getState, dispatch, rejectWithValue }) => {
    const {
      orderDetails: { id = '' },
    } = (getState() as RootState).orders

    try {
      const response = await api.delete(`shipper/api/delete-order-document/${fileId}/`)
      dispatch(getOrderDocuments(id))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const archiveOrder = createAsyncThunk(
  'orders/archiveOrder',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const {
      orderDetails: { id = '' },
    } = (getState() as RootState).orders

    try {
      const response = await api.delete(`shipper/api/order-rud/${id}/`)
      dispatch(getOrderDetails(id))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const bulkArchiveOrders = createAsyncThunk(
  'orders/bulkArchiveOrders',
  async (orderIds: number[], { dispatch, rejectWithValue }) => {
    try {
      const response = await api.post(
        `shipper/api/bulk-archive-load-order/`,
        keysToSnakeCase({
          orderIds,
        }),
      )
      dispatch(getOrders())
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateOrder = createAsyncThunk(
  'orders/updateOrder',
  async (data: OrderDetails, { getState, dispatch, rejectWithValue }) => {
    const { orderDetails } = (getState() as RootState).orders

    const payload = getObjectDifferences(data, orderDetails)

    try {
      const response = await api.patch(
        `shipper/api/order-rud/${orderDetails?.id}/`,
        keysToSnakeCase(payload),
      )
      dispatch(getOrderDetails(orderDetails?.id || ''))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getOrderConfirmation = createAsyncThunk(
  'orders/getOrderConfirmation',
  async (ids: string, { rejectWithValue }) => {
    try {
      const response = await api.get(`shipper/api/order-detail-list/`, {
        params: {
          limit: 100,
          ids,
        },
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const linkOrUnlinkOrder = createAsyncThunk(
  'orders/linkOrUnlinkOrder',
  async (
    { orderId, action }: { orderId: number; action: 'LINK' | 'UNLINK' },
    { getState, dispatch, rejectWithValue },
  ) => {
    const { loadDetails } = (getState() as RootState).loads

    try {
      await api.post(
        'shipper/api/link-unlink-load-order/',
        keysToSnakeCase({ loadId: loadDetails.id, orderId, action }),
      )
      dispatch(getLoadDetail(loadDetails.id))
      return keysToCamelCase(action)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getOrdersByQuery = createAsyncThunk(
  'orders/getOrdersByQuery',
  async (q: string, { getState, rejectWithValue }) => {
    const { ordersByQueryLimit: limit, ordersByQueryOffset: offset } = (getState() as RootState)
      .orders

    try {
      const response = await api.get('shipper/api/search-load-order/', {
        params: { q, limit, offset },
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const createLoadFromSelectedOrders = createAsyncThunk(
  'orders/createLoadFromSelectedOrders',
  async (_, { getState, rejectWithValue }) => {
    const { ordersToConfirm } = (getState() as RootState).orders

    const formatLocation = (location: any, type?: 'pickup' | 'dropoff') => ({
      ...location,
      location: { ...location.location, name: location.name },
      ...(type && { type }),
    })

    const payload = {
      ...normalizeLoadPayload({
        origin: formatLocation(ordersToConfirm[0].pickupLocation),
        destination: formatLocation(ordersToConfirm[0].dropLocation),
        loadStops:
          ordersToConfirm.length === 1
            ? []
            : ordersToConfirm
                .map((order, i) => {
                  if (!i) return []
                  const stops = [
                    formatLocation(order.pickupLocation, 'pickup'),
                    formatLocation(order.dropLocation, 'dropoff'),
                  ]
                  return [...stops]
                })
                .flat(),
        orders: ordersToConfirm.reduce((acc: any, order, index) => {
          if (index === 0) acc.push({ orderId: order.id, stopNumber: null })
          else {
            const stopNumber = (index - 1) * 2 // Calculate the stop number for the current index
            acc.push({ orderId: order.id, stopNumber: stopNumber })
            acc.push({ orderId: order.id, stopNumber: stopNumber + 1 })
          }
          return acc
        }, []),
      }),
      network: PRIVATE_NETWORK,
    }

    try {
      const response = await api.post(
        '/loads/api/v1/external/create-load/',
        keysToSnakeCase(payload),
      )
      return response.data.id
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const createOrder = createAsyncThunk(
  'orders/createOrder',
  async (_, { getState, rejectWithValue }) => {
    const { newOrder } = (getState() as RootState).orders

    const payload = keysToSnakeCase({
      orderPickupNumber: newOrder.orderPickupNumber || null,
      poNumber: newOrder.poNumber || null,
      salesNumber: newOrder.salesNumber || null,
      stockNumber: newOrder.stockNumber || null,
      budget: newOrder.budget || null,
      ...(newOrder.origin?.id
        ? { pickupId: newOrder.origin?.id }
        : {
            pickupLocation: {
              name: newOrder.origin?.postalCode || newOrder.origin?.title,
              location: {
                ...newOrder.origin,
                postalCode: newOrder.origin?.postalCode || null,
                addressLines: newOrder.origin?.address || null,
              },
            },
          }),
      ...(newOrder.destination?.id
        ? { dropId: newOrder.destination?.id }
        : {
            dropLocation: {
              name: newOrder.destination?.postalCode || newOrder.destination?.title,
              location: {
                ...newOrder.destination,
                postalCode: newOrder.destination?.postalCode || null,
                addressLines: newOrder.destination?.address || null,
              },
            },
          }),
    })

    try {
      const response = await api.post('shipper/api/list-create-order/', payload)
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setLimit(state, { payload }) {
      state.limit = payload
    },
    setOffset(state, { payload }) {
      state.offset = payload
    },
    setFilters(state, { payload }) {
      state.filters = payload
    },
    setOrder(state, { payload }) {
      state.order = payload
    },
    setNewOrder(state, { payload }) {
      state.newOrder = payload
    },
    setOrdersByQueryLimit(state, { payload }) {
      state.ordersByQueryLimit = payload
    },
    setOrdersByQueryOffset(state, { payload }) {
      state.ordersByQueryOffset = payload
    },
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getOrders.pending, state => {
        state.loading.getOrders = true
      })
      .addCase(getOrders.fulfilled, (state, { payload }) => {
        const { count, results } = payload
        state.loading.getOrders = false
        state.count = count
        if (count) state.initialOrdersCount = count
        state.orders = results
      })
      .addCase(getOrders.rejected, (state, { payload }) => {
        state.loading.getOrders = false
        toast.error(getErrorString(payload, 'Failed to get orders'))
      })
      .addCase(createOrder.pending, state => {
        state.loading.createOrder = true
      })
      .addCase(createOrder.fulfilled, state => {
        state.loading.createOrder = false
        toast.success('Successfully created new order')
      })
      .addCase(createOrder.rejected, (state, { payload }) => {
        state.loading.createOrder = false
        toast.error(getErrorString(payload, 'Failed to create new order'))
      })
      .addCase(getOrderDetails.pending, state => {
        state.loading.getOrderDetails = true
      })
      .addCase(getOrderDetails.fulfilled, (state, { payload }) => {
        state.loading.getOrderDetails = false
        state.orderDetails = payload
      })
      .addCase(getOrderDetails.rejected, (state, { payload }) => {
        state.loading.getOrderDetails = false
        toast.error(getErrorString(payload, 'Failed to get order details'))
      })
      .addCase(getOrderDocuments.pending, state => {
        state.loading.getOrderDocuments = true
      })
      .addCase(getOrderDocuments.fulfilled, (state, { payload }) => {
        state.loading.getOrderDocuments = false
        state.documents = payload.results
      })
      .addCase(getOrderDocuments.rejected, (state, { payload }) => {
        state.loading.getOrderDocuments = false
        toast.error(getErrorString(payload, 'Failed to get order documents'))
      })
      .addCase(uploadOrderDocuments.pending, state => {
        state.loading.uploadOrderDocuments = true
      })
      .addCase(uploadOrderDocuments.fulfilled, state => {
        state.loading.uploadOrderDocuments = false
        toast.success('Successfully uploaded document(s)')
      })
      .addCase(uploadOrderDocuments.rejected, (state, { payload }) => {
        state.loading.uploadOrderDocuments = false
        toast.error(getErrorString(payload, 'Failed to upload document(s)'))
      })
      .addCase(deleteOrderDocument.pending, state => {
        state.loading.deleteOrderDocument = true
      })
      .addCase(deleteOrderDocument.fulfilled, state => {
        state.loading.deleteOrderDocument = false
        toast.success('Successfully deleted document')
      })
      .addCase(deleteOrderDocument.rejected, (state, { payload }) => {
        state.loading.deleteOrderDocument = false
        toast.error(getErrorString(payload, 'Failed to delete document'))
      })
      .addCase(updateOrder.pending, state => {
        state.loading.updateOrder = true
      })
      .addCase(updateOrder.fulfilled, state => {
        state.loading.updateOrder = false
        toast.success('Successfully updated order')
      })
      .addCase(updateOrder.rejected, (state, { payload }) => {
        state.loading.updateOrder = false
        toast.error(getErrorString(payload, 'Failed to update order'))
      })
      .addCase(getOrderConfirmation.pending, state => {
        state.loading.getOrderConfirmation = true
      })
      .addCase(getOrderConfirmation.fulfilled, (state, { payload }) => {
        state.loading.getOrderConfirmation = false
        state.ordersToConfirm = payload
      })
      .addCase(getOrderConfirmation.rejected, (state, { payload }) => {
        state.loading.getOrderConfirmation = false
        toast.error(getErrorString(payload, 'Failed to get orders'))
      })
      .addCase(createLoadFromSelectedOrders.pending, state => {
        state.loading.createLoadFromSelectedOrders = true
      })
      .addCase(createLoadFromSelectedOrders.fulfilled, state => {
        state.loading.createLoadFromSelectedOrders = false
        toast.success('Successfully created load')
      })
      .addCase(createLoadFromSelectedOrders.rejected, (state, { payload }) => {
        state.loading.createLoadFromSelectedOrders = false
        toast.error(getErrorString(payload, 'Failed to create load'))
      })
      .addCase(archiveOrder.pending, state => {
        state.loading.archiveOrder = true
      })
      .addCase(archiveOrder.fulfilled, state => {
        state.loading.archiveOrder = false
        toast.success('Successfully archived order')
      })
      .addCase(archiveOrder.rejected, (state, { payload }) => {
        state.loading.archiveOrder = false
        toast.error(getErrorString(payload, 'Failed to archive order'))
      })
      .addCase(linkOrUnlinkOrder.pending, state => {
        state.loading.linkOrUnlinkOrder = true
      })
      .addCase(linkOrUnlinkOrder.fulfilled, (state, { payload }) => {
        state.loading.linkOrUnlinkOrder = false
        toast.success(`Successfully ${payload === 'LINK' ? 'added' : 'removed'} order`)
      })
      .addCase(linkOrUnlinkOrder.rejected, (state, { payload }) => {
        state.loading.linkOrUnlinkOrder = false
        toast.error(getErrorString(payload, 'Failed to add or remove order'))
      })
      .addCase(getOrdersByQuery.pending, state => {
        state.loading.getOrdersByQuery = true
      })
      .addCase(getOrdersByQuery.fulfilled, (state, { payload }) => {
        const { count, results } = payload
        state.loading.getOrdersByQuery = false
        state.ordersByQuery = results
        state.ordersByQueryCount = count
      })
      .addCase(getOrdersByQuery.rejected, (state, { payload }) => {
        state.loading.getOrdersByQuery = false
        toast.error(getErrorString(payload, 'Failed to get orders'))
      })
      .addCase(bulkArchiveOrders.pending, state => {
        state.loading.bulkArchiveOrders = true
      })
      .addCase(bulkArchiveOrders.fulfilled, state => {
        state.loading.bulkArchiveOrders = false
        toast.success('Successfully archived selected orders')
      })
      .addCase(bulkArchiveOrders.rejected, (state, { payload }) => {
        state.loading.bulkArchiveOrders = false
        toast.error(getErrorString(payload, 'Failed to archive selected orders'))
      })
  },
})

export const {
  setLimit,
  setOffset,
  setFilters,
  setOrder,
  setOrdersByQueryLimit,
  setOrdersByQueryOffset,
  reset,
  setNewOrder,
} = ordersSlice.actions

export default ordersSlice.reducer
